export default [
  {
    key: 'tran_id',
    label: 'field.abaTransaction',
    type: 'text',
    cols: 12,
  },
  {
    key: 'gameId',
    label: 'field.game_id',
    type: 'text',
    cols: 6,
  },
]
