<template>
  <div>
    <load-profile></load-profile>
    <n-search-container
      :show-header="true"
      :defaultVisible="true"
      @search="search"
      @reset="reset"
      :fields="searchFields"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-0-5"></div>
      <div>
        <n-table
          @sort-changed="sortChanged"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
        >
          <template #cell(paymentSuccess)="data">
            <b-badge
              pill
              variant="danger"
              badge-glow
              v-if="data.item.paymentSuccess === 0"
            >
              Failed
            </b-badge>
            <b-badge
              pill
              variant="success"
              badge-glow
              v-else-if="data.item.paymentSuccess === 1"
            >
              Success
            </b-badge>
          </template>
        </n-table>
        <n-pagination
          class="mt-2"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
        ></n-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BBadge,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BFormRadioGroup,
  BTab,
  BTabs,
  BFormTextarea,
  BButton,
  BCardHeader,
  BCardBody,
} from 'bootstrap-vue'
import Repository from '@/repositories/RepositoryFactory'
import Ripple from 'vue-ripple-directive'
import NButtonLoading from '@/components/NButtonLoading'
import NFormConfirmation from '@/components/NFormConfirmation'
import NPagination from '@/components/NPagination'
import NInput from '@/components/NInput'
import NSearchContainer from '@/components/NSearchContainer'
import NSearchInput from '@/components/NSearchInput'
import NTable from '@/components/NTable'
import searchInputs from './searchInput'
import tableFields from './tableField'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

const AbaTransactionRepository = Repository.get('checkTransaction')

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BBadge,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BFormRadioGroup,
    NButtonLoading,
    NFormConfirmation,
    NTable,
    NPagination,
    NSearchInput,
    NSearchContainer,
    NInput,
    BTab,
    BTabs,
    BFormTextarea,
    BButton,
    BCardHeader,
    BCardBody,
  },
  directives: {
    Ripple,
  },
  data() {
    this.searchFields = this.searchFields.map((field) => {
      return field
    })
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        name: this.$route.query.name || null,
        isEnable: this.$route.query.isEnable || null,
        limit: this.$store.state.pagination.perPage || 10,
        gameId: this.$route.query.game_id || '',
        tran_id: this.$route.query.tran_id || '',
        sortPay: null,
        sortType: null,
        type: null,
      },
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
      loadingReport: false,
      report: {
        preOrderSum: 0,
        manualSum: 0,
        instantSum: 0,
      },
    }
  },
  mounted() {},
  watch: {
    perPage(value) {
      this.list(1)
    },
  },
  computed: {
    accordion() {
      return this.$parent.accordion
        ? `accordion-${this.$parent.collapseID}`
        : null
    },
    perPage: {
      get() {
        return this.$store.state.pagination.perPage
      },
      set(value) {
        this.$store.commit('pagination/SET_PER_PAGE', value)
      },
    },
    activeSearch() {
      return !!this.$route.query.name || !!this.$route.query.isEnable
    },
    fields() {
      let fieldKeys = tableFields.map((item) => {
        if (item.key == 'name') {
          item.sortField = `name_${this.$i18n.locale}`
        }
        return item
      })
      return fieldKeys
    },
  },
  mounted() {},

  methods: {
    search() {
      this.getData()
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy
      this.params.sort = sortDirection
      this.updateQuerySting()
      this.getData()
    },
    list(page = 1) {
      this.params.page = page
      this.updateQuerySting()
      this.getData()
    },
    reset() {
      this.params.search = ''
      this.$refs.search.reset()
      this.getData()
    },
    getData() {
      this.loading = true
      console.log(this.params.gameId, 'game id')
      if (this.params.tran_id || this.params.gameId) {
        AbaTransactionRepository.checkTranId(
          this.params.tran_id,
          this.params.gameId
        )
          .then((response) => {
            let data = response.data
            console.log(data)
            this.items = [...data]
            this.total = 1
          })
          .catch(() => {
            this.items = []
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.loading = false
        this.items = []
      }
    },
  },
  setup() {
    let keyFields = [...tableFields]
    const searchFields = [...searchInputs]

    return { keyFields, searchFields }
  },
}
</script>
